import { useCallback, useEffect, useState } from 'react'
import { CreditCardData, CreditCardValidate } from '@open-tender/types'
import { useCreditCardForm } from '@open-tender/utils'
import {
  addCustomerCreditCard,
  resetCustomerCreditCardsError,
  selectBrand,
  selectCustomerCreditCards,
  selectRecaptcha,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonSubmit,
  CreditCard,
  FormError,
  FormRecaptcha,
  FormSubmit,
  Recaptcha,
} from 'components'

const makeErrMsg = (formError: string | undefined) => {
  if (!formError || formError === 'Missing Customer') return null
  return formError.includes('parameters')
    ? 'There are one or more errors below'
    : formError
}

const CreditCardForm = ({
  windowRef,
  callback,
  submitText = 'Add New Card',
  submittingText = 'Authorizing Card...',
  revenue_center_id = null,
}: {
  windowRef: React.RefObject<HTMLDivElement>
  callback: () => void
  submitText?: string
  submittingText?: string
  revenue_center_id: number | null
}) => {
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const dispatch = useAppDispatch()
  const { use_enterprise_recaptcha } = useAppSelector(selectBrand) || {}
  const { addCard: includeRecaptcha } = useAppSelector(selectRecaptcha) || {
    addCard: false,
  }
  const isSubmitDisabled =
    includeRecaptcha && !use_enterprise_recaptcha && !recaptchaToken

  const { loading, error } = useAppSelector(selectCustomerCreditCards)

  const addCard = useCallback(
    (data: CreditCardData, callback: () => void) =>
      dispatch(
        addCustomerCreditCard({
          data,
          callback: () => {
            callback?.()
          },
        })
      ),
    [dispatch]
  )
  const [newCard, setNewCard] = useState<
    (CreditCardValidate & { isComplete: boolean }) | null
  >(null)
  const {
    card: cardData = null,
    cardType = null,
    // isComplete = false,
    // errors: cardErrors,
  } = newCard || {}
  const { submitRef, recaptchaRef, errors, submitting, handleSubmit } =
    useCreditCardForm(
      loading,
      error,
      cardData,
      cardType,
      addCard,
      callback,
      includeRecaptcha,
      revenue_center_id
    )
  const errMsg = makeErrMsg(errors.form)

  useEffect(() => {
    dispatch(resetCustomerCreditCardsError())
    return () => {
      dispatch(resetCustomerCreditCardsError())
    }
  }, [dispatch])

  useEffect(() => {
    if (!error) return
    if (windowRef.current) windowRef.current.scrollTop = 0
  }, [error, windowRef])

  useEffect(() => {
    if (!error && !errors) return

    const timeout = setTimeout(() => {
      const firstErrorElement =
        document.querySelector<HTMLInputElement>('.error')
      if (firstErrorElement) setTimeout(() => firstErrorElement.focus())
    })

    return () => clearTimeout(timeout)
  }, [error, errors])

  return (
    <form id="credit-card-form" onSubmit={handleSubmit} noValidate>
      <FormError errMsg={errMsg} style={{ margin: '0 0 2rem' }} />
      <CreditCard setCard={setNewCard} formErrors={errors} />
      {includeRecaptcha && (
        <FormRecaptcha>
          <Recaptcha onChange={setRecaptchaToken} recaptchaRef={recaptchaRef} />
        </FormRecaptcha>
      )}
      <FormSubmit>
        <ButtonSubmit
          submitRef={submitRef}
          submitting={submitting}
          disabled={isSubmitDisabled}
        >
          {submitting ? submittingText : submitText}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default CreditCardForm
