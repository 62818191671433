import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { selectBrand, selectMenu, useAppSelector } from '@open-tender/cloud'
import { selectMenuSlug } from '@open-tender/cloud'
import { makeFeatured } from '@open-tender/utils'
import { Content, Loading, Main } from 'components'
import {
  MenuCategoryHeader,
  MenuHeader,
  MenuItems,
  MenuItem,
  MenuCategoryView,
} from '../Menu'
import useMenuItemsFilter from '../Menu/useMenuItemsFilter'

const MenuFeatured = () => {
  const navigate = useNavigate()
  const { title: siteTitle } = useAppSelector(selectBrand) || {}
  const { categories, loading } = useAppSelector(selectMenu)
  const isLoading = loading === 'pending'
  const menuSlug = useAppSelector(selectMenuSlug)
  const { filterItems } = useMenuItemsFilter()
  const featured = filterItems(makeFeatured(categories))
  const shouldRedirect = !isLoading && featured.length === 0

  useEffect(() => {
    if (shouldRedirect) navigate(menuSlug)
  }, [navigate, shouldRedirect, menuSlug])

  if (isLoading) return null

  return (
    <>
      <Helmet>
        <title>Favorites | {siteTitle}</title>
      </Helmet>
      <Content scrollTop={false}>
        <MenuHeader />
        <Main>
          <MenuCategoryView>
            <MenuCategoryHeader title="Featured" />
            {isLoading ? (
              <Loading />
            ) : (
              <MenuItems>
                {featured.map((item, index) => (
                  <MenuItem key={`${item.id}-${index}`} item={item} />
                ))}
              </MenuItems>
            )}
          </MenuCategoryView>
        </Main>
      </Content>
    </>
  )
}

export default MenuFeatured
