export const getActiveElement = (elements: HTMLElement[], offset = 0) => {
  return elements
    .filter((i) => i.getBoundingClientRect().top <= offset)
    .reduce((max: HTMLElement | null, i: HTMLElement) => {
      return max &&
        max.getBoundingClientRect().top > i.getBoundingClientRect().top
        ? max
        : i
    }, null)
}

// https://stackoverflow.com/questions/51229742/javascript-window-scroll-behavior-smooth-not-working-in-safari
export const smoothHorizontalScrolling = (
  container: HTMLElement,
  time: number,
  amount: number,
  start: number
) => {
  let eAmt = amount / 100
  let curTime = 0
  let scrollCounter = 0
  while (curTime <= time) {
    window.setTimeout(shs, curTime, container, scrollCounter, eAmt, start)
    curTime += time / 100
    scrollCounter++
  }
}

const shs = (
  elem: HTMLElement,
  scrollCounter: number,
  eAmt: number,
  start: number
) => {
  const scrolledAmount = eAmt * scrollCounter + start
  elem.scrollLeft = scrolledAmount
}

export const getRecaptchaKey = (useEnterprise?: boolean) => {
  const key = useEnterprise
    ? process.env.REACT_APP_ENTERPRISE_RECAPTCHA_KEY
    : process.env.REACT_APP_RECAPTCHA_KEY
  return key || null
}
