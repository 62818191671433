import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSignInForm } from '@open-tender/utils'
import {
  loginCustomer,
  resetLoginError,
  resetOneTimePasscode,
  selectBrand,
  selectCustomer,
  selectGuest,
  selectOneTimePasscode,
  selectRecaptcha,
  signInCustomer,
  useAppDispatch,
  useAppSelector,
} from '@open-tender/cloud'
import {
  ButtonSubmit,
  FormError,
  FormInputs,
  FormRecaptcha,
  FormSubmit,
  Recaptcha,
} from 'components'
import { FormFieldType } from '@open-tender/types'
import { ThirdPartyLoyaltySignUpMessage } from '../ThirdPartyLoyalty'

const SignInForm = () => {
  const dispatch = useAppDispatch()
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null)
  const { tpls, use_enterprise_recaptcha } = useAppSelector(selectBrand) || {}
  const hasPunchh = tpls === 'PUNCHH'
  const { email } = useAppSelector(selectGuest)
  const customer = useAppSelector(selectCustomer)
  const signIn = useAppSelector(selectOneTimePasscode)

  const { login: includeRecaptcha } = useAppSelector(selectRecaptcha) || {}

  const isSubmitDisabled =
    includeRecaptcha && !use_enterprise_recaptcha && !recaptchaToken

  const loading = useMemo(
    () => (hasPunchh ? signIn.loading : customer.loading),
    [hasPunchh, customer.loading, signIn.loading]
  )
  const error = useMemo(
    () => (hasPunchh ? signIn.error : customer.error),
    [hasPunchh, customer.error, signIn.error]
  )

  const loginDefault = useCallback(
    (email: string, password?: string, recaptcha_token?: string) =>
      dispatch(
        loginCustomer({ email, password: password as string, recaptcha_token })
      ),
    [dispatch]
  )
  const loginTpls = useCallback(
    (email: string, password?: string, recaptcha_token?: string) =>
      dispatch(signInCustomer({ data: { email, password, recaptcha_token } })),
    [dispatch]
  )
  const login = hasPunchh ? loginTpls : loginDefault

  const loginCallback = () => {}

  const showSignUpMessage =
    hasPunchh && error?.code === 'MEMBER_SIGNUP_REQUIRED'

  const {
    submitRef,
    recaptchaRef,
    fields,
    data,
    errors,
    submitting,
    handleChange,
    handleSubmit,
  } = useSignInForm(
    email || '',
    loading,
    error,
    login,
    includeRecaptcha,
    loginCallback
  )

  const onChange = (field: FormFieldType, value: string | number | boolean) => {
    handleChange(field.name, value)
  }

  useEffect(() => {
    if (error) {
      window.scrollTo(0, 0)
      setRecaptchaToken(null)
    }
  }, [error])

  useEffect(() => {
    dispatch(resetLoginError())
    dispatch(resetOneTimePasscode())
    return () => {
      dispatch(resetLoginError())
      dispatch(resetOneTimePasscode())
    }
  }, [dispatch])

  return (
    <form id="checkout-signin-form" onSubmit={handleSubmit} noValidate>
      {showSignUpMessage ? (
        <ThirdPartyLoyaltySignUpMessage />
      ) : (
        <FormError errMsg={errors.form} style={{ margin: '0 0 2rem' }} />
      )}
      <FormInputs
        fields={fields}
        data={data}
        onChange={onChange}
        errors={errors}
      />

      {includeRecaptcha && (
        <FormRecaptcha>
          <Recaptcha onChange={setRecaptchaToken} recaptchaRef={recaptchaRef} />
        </FormRecaptcha>
      )}

      <FormSubmit>
        <ButtonSubmit
          disabled={isSubmitDisabled}
          submitRef={submitRef}
          submitting={submitting}
        >
          {submitting ? 'Submitting...' : 'Sign In'}
        </ButtonSubmit>
      </FormSubmit>
    </form>
  )
}

export default SignInForm
